.box-detail {
  font-weight: 500;
  padding: 25px;

  span {
    display: block;
    font-size: 12px;
    color: var(--grey-color);
    font-weight: 400;
  }
}

@media (max-width:1100px) {
  .box-detail{
    padding: 4%;
    line-height: 1.2;
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span{
      font-size: 11px;
      font-weight: 400;
      line-height: 1.4;
    }
  }
}
