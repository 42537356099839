.section-create-coupon{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    a{
        color: white;
        text-decoration: none;
    }

    .create-coupon-button{
        width: 100%;
    }
}

.coupon-modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-body);
    width: 100%;


    h2{
        color: white;
        text-align: center;
        margin-top: 3rem;
    }

    .hm_hyperModalWrapper .hm_hyperModalContentWrapper {
        display: flex;
      }

    .coupon-modal-label-text{
        display: flex;
        flex-direction: row;
        width: 65%  ;
        justify-content: space-between;
        h4, p{
            color: white;
        }
    }
}

th{
    align-items: center;
    text-align: center !important;
    color: white;
}