.hm_hyperModalWrapper .hm_hyperModalContentWrapper {
  display: flex;
  justify-content: center;
  width: 25% !important;
  height: 45% !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 8rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 1rem;
  padding: 0.5rem 0;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -4rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.checkbox-eyeIcon-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.preview-image-download-button{
  background: none;
  border: none;
  text-align: center;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}

.modal-image-cover{
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.file-detail {
  align-items: center;
  padding: 0.75rem 5px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  border: var(--border);
  border-radius: 5px;
  .extension {
    flex: 0 0 auto;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    background-color: rgba(126, 87, 194, 0.18);
    color: var(--highlight-color);
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 15px;
  }

  .txt-file {
    flex: 1 0 0%;
    max-width: 61%;
    h4 {
      margin: 0;
      font-weight: 500;
      font-size: 11px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p {
      display: block;
      font-size: 12px;
      color: var(--grey-color);
      font-weight: 400;
      margin: 3px 0 0;
    }
  }

  .btn-file-download {
    flex: 0 0 auto;
    width: auto;
    appearance: none;
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
}
