.header-component{
    width: 100%;
    background: var(--background-element);
    border-bottom: var(--border);
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2rem;
    color: rgba(255, 255, 255, 0.9);
    font-size: .9rem;

    .signout-btn, .mobile-btn{
        background-color: transparent;
        border: none;
        outline: none;
        width: max-content;

        img{
            height: 1rem;
            margin-left: .5rem;
            cursor: pointer;
        }
    }

    .mobile-btn{
        display: none;
    }

    .mobile-background{
        display: none;
    }

}

@media(max-width:992px){
    .header-component{
        justify-content: space-between;
        padding: 0 1rem;

        .signout-btn,
        .mobile-btn{
            display: block;

            img{
                margin-left: 0;
            }
        }

        .mobile-background{
            width: 100%;
            height: 100vh;
            position: fixed;
            background-color: rgba(129, 129, 129, .64);
            left: 0;
            top: 0;
            border: none;
            outline: none;

            &.active{
                display: block;
            }
        }
    }
}
