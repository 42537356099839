@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Libre+Franklin:wght@400;500;800;900&display=swap");

:root {
  --primary-color: #2a2a4a;
  --secondary-color: #233eb4;
  --highlight-color: #8928e9;
  --error: rgb(226, 92, 92);
  --font: "Libre Franklin", sans-serif;
  --font-secondary: "Dela Gothic One", cursive;
  --gradient: linear-gradient(140deg, #05acdf 1%, #9d2be5 39%, #ea2fb2 58%, #ffc634 92%);
  --background-body: #1a1a3c;
  --background-element: #2a2a4a;
  --color: #dedefd;
  --grey-color: #b7b7b7;
  --border: 1px solid rgba(255, 255, 255, 0.1);
  --fill-item-library: #141030;
  --input-color: #DEDEFC66;
  --btn-mini-save: #7C39E0;
  --red-delete: #EB3F35;
  --active-paginator: #594D77;
  --hover-paginator: #40345C;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

html {
  scroll-behavior: smooth;
  width: 100%;
}

body {
  margin: 0;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  width: 100%;
  position: relative;
  color: white;
  background-color: var(--background-body);
}

@-moz-document url-prefix() {
  body {
    -webkit-text-stroke: 0.5px;
  }
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

.content-gral {
  min-height: calc(100vh - 4rem - 3rem);
  padding: 2rem 7% 2rem 22rem;
}

.back-content {
  padding: 25px;
  box-sizing: border-box;
  border-radius: 7px;
  background: var(--background-element);
}

.delete-red{
  color: var(--red-delete);
}

.box-form {
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--color);
    display: block;
  }

  select {
    display: block;
    margin-top: 7px;
    width: 100%;
    background: var(--background-element);
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    font-family: var(--font);
    color: #323a46;
    color: var(--color);
    padding: 0.45rem 2.7rem 0.45rem 0.9rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    border: var(--border);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.9rem center;
    background-size: 14px 10px;
  }
}

.file-btn {
  margin-bottom: 1rem;
  input {
    font-family: var(--font);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  input[type="file" i]::-webkit-file-upload-button,
  input::file-selector-button {
    background-color: var(--highlight-color);
    border: none;
    color: white;
    font-family: var(--font);
    padding: 5px 7px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
  }
}

.status {
  padding: 8px 0.75rem;
  border-radius: 15px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  display: block;
  width: fit-content;
  color: #cbcbcb;
  background-color: rgba(110, 110, 110, 0.28);

  &.approvedClass {
    background-color: rgba(0, 230, 130, 0.1);
    color: #09ad95;
  }

  &.approvedByAdminClass {
    background-color: rgba(140, 255, 205, 0.1);
    color: #f5ee17;
  }

  &.pendingClass {
    color: #f7b731;
    background-color: rgba(255, 162, 43, 0.1);
  }
  &.rejectedClass{
    color: #e82646;
    background-color: rgba(255, 56, 43, 0.1);
  }
  &.printClass{
    color: #a098ff;
    background-color: rgba(110, 97, 255, .18);
  }
  &.sendClass{
    color: #05c3fb;
    background-color: rgba(5, 195, 251, 0.1);
  }
  &.sentClass{
    color: #09ad95;
    background-color: rgba(0, 230, 130, 0.1);
  }
}

.btn-gral {
  border: none;
  padding: 0.7rem 1.4rem;
  color: #fff;
  border-radius: 0.6rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  font-family: var(--font);
  background-color: var(--highlight-color);
  transition: 0.15s ease-in-out all;
  -webkit-user-select: none;
  user-select: none;

  &:active {
    transform: scale(0.95);
  }
}
.btn-mini-cancel {
  border: none;
  padding: 0.7rem 1.4rem;
  color: #fff;
  border: 2px solid white;
  border-radius: 42px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  font-family: var(--font);
  background-color: transparent;
  transition: 0.15s ease-in-out all;
  -webkit-user-select: none;
  user-select: none;

  &:active {
    transform: scale(0.95);
  }
}
.btn-mini-save{
  border: none;
  padding: 0.7rem 1.4rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  font-family: var(--font);
  background-color: var(--btn-mini-save);
  transition: 0.15s ease-in-out all;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 5px 5px 30px rgba(87, 10, 99, 0.25);
  border-radius: 42px;

  &:active {
    transform: scale(0.95);
  }
}
.btn-secondary {
  background: rgba(255, 255, 255, 0.15);
  border: none;
  color: white;
}
.back-btn {
  display: block;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: var(--color);
  text-decoration: none;
  font-size: 12px;
}

.error-txt {
  font-size: 0.7rem;
  color: var(--error);
  text-align: center;
}

.title-page {
  font-weight: 500;
  font-size: 24px;
  color: var(--color);
  margin: 0;
}

.subtitle {
  margin: 0 0 20px;
  font-weight: 500;
  padding-bottom: 7px;
  color: var(--color);
  border-bottom: var(--border);
}

.line-form {
  margin-bottom: 1rem;
  width: 100%;

  span {
    font-size: 13px;
    color: var(--color);
    display: block;
    margin-bottom: 3px;
    font-family: var(--font);
  }

  input,
  select,
  textarea {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    padding: 0.7rem 1rem;
    width: 100%;
    background-color: transparent;
    font-family: var(--font);
    resize: vertical;
    min-height: 2rem;
    color: var(--color);

    &::placeholder {
      font-family: var(--font);
      color: var(--color);
    }
  }
}

.filter-container {
  margin-top: 3rem;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 7px;
  background: var(--background-element);
  color: var(--background-element);

  .scrolleable {
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          th {
            border-bottom: var(--border);
            text-align: left;
            font-weight: 500;
            padding: 0.5rem 1rem;
            white-space: nowrap;
            color: var(--color);

            &.small {
              width: 2rem;
            }
          }
        }
      }

      tbody {
        tr {
          text-align: center;
          &:nth-child(odd) {
            td {
              background-color: rgb(255 255 255 / 3%);

            }
          }

          td {
            color: var(--color);
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
            text-align: center !important;

            img {
              width: 1.2rem;
              height: 1.2rem;
              cursor: pointer;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}


.table-container {
  margin-top: 3rem;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 7px;
  background: var(--background-element);
  color: var(--background-element);

  .scrolleable {
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          th {
            border-bottom: var(--border);
            text-align: left;
            font-weight: 500;
            padding: 0.5rem 1rem;
            white-space: nowrap;
            color: var(--color);

            &.small {
              width: 2rem;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            td {
              background-color: rgb(255 255 255 / 3%);
            }
          }

          td {
            color: var(--color);
            padding: 0.8rem 1rem;
            font-size: 0.9rem;
            text-align: -webkit-center;


            img {
              width: 1.2rem;
              height: 1.2rem;
              cursor: pointer;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .content-gral {
    padding: 2rem 4% 2rem 19rem;
  }
}

@media (max-width: 1024px) {
  * {
    cursor: default !important;
  }

  .content-gral {
    padding: 2rem 0;
  }

  .table-container {
    overflow: scroll;

    .scrolleable {
      table {
        width: 170vw;
      }
    }
  }
}

@media (max-width: 992px) {
  .content-gral {
    padding: 2rem 0;
    width: 90%;
    margin: 0 auto;
  }
}
