.user-details {
  width: 100%;
}
.box-form {
  display: flex;
  justify-content: space-between;

}
.box-personal-info {
  display: inline-block;
  width: 50%;
  .subtitle {
    border-bottom: none;
    display: block;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
}
.box {
  background-color: var(--fill-item-library);
  border-radius: 5px;
  text-align: center;
  padding: 3rem;
}

.list-component {
  display: flex;
  flex-direction: row;
  height: 10rem;
  flex-wrap: wrap;
  .list-divisor{
    button{
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    img {
      padding-left: .7rem;
    }
  }
  p {
    background: rgba(137, 39, 232, 0.3);
    border-radius: 37px;
    color: white;
    padding: .8rem;
    margin-left: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;
  }
}

.box-file-charged {
  background-color: var(--fill-item-library);
  border-radius: 3px;
  text-align: center;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;

  .img-section {
    img{
      width: 150px;
      height: 150px;
    }
  }
  .selectedFile-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    width: 65%;
   .selectedFile-section{
     h4{
      margin-top: -.4rem;
    }
  }
  .selectedFile-img{
    display: flex;
    flex-direction: row;
    .delete-red{
      margin-left: .3rem;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
}

.buttonsItemLibrary {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 1rem 1.1rem 0rem;
  .btn-mini-save {
    margin-left: 1.5rem;
  }
}

.inputfile-1 + label {
  color: #fff;
  background-color: #c39f77;
}

.box-address-info {
  display: inline-block;
  width: 45%;
  padding: 1rem;
  .subtitle {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0rem;
    border-bottom: none;
  }
}

.btn-gral{
  width: 70%;
}

.inputFilter {
  input,
  select {
    width: 100%;
    padding: 0 1rem;
    background-color: transparent;
    color: var(--input-color);
    border: var(--border);
    height: 40px;
    line-height: 40px;

    &.remove-border-right {
      border-right: 0;
    }
    option{
      color: black;
    }
  }
  input::placeholder {
    color: var(--input-color);
  }
  h2 {
    color: white;
    margin: 0%;
    margin-bottom: 7px;
    font-size: 12px;
    font-weight: 500;
  }
}
