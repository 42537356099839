
.box-client {
  display: flex;

}
.box-personal-info {
  display: inline-block;
  width: 50%;
  .subtitle {
    border-bottom: none;
    display: block;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
}
.box-address-info {
  display: inline-block;
  width: 50%;
  .subtitle {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0rem;
    border-bottom: none;
  }
}

.comment-box {
  background-color: var(--greyColor);
  border-radius: 10px;
  text-align: start;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  height: 260px;
  width: 500px;
  align-content: center;
  justify-content: flex-start;
  overflow-y: scroll;
  margin-bottom: 1.5rem;

  span{
    color: #6d6d6d;
    font-weight: 500;
  }

  p {
    color: var(--darkFont);
    font-weight: 500;
    font-size: 15px;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 0rem;
  }

  h2{
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }

}

.test {
  width: 10px;
  height: 10px;
}


.new-message {
  width: 100%;
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;


  .button-triangle {
    cursor: pointer;
    background-color: #8928e9;
    border-radius: 20px;
    width:10%;
    border-style: none;
    bottom: 35px;
    left: 417px;
    padding: 6px;
  }

  .msg{
    width: 70%;
    margin-left: 0.5rem;
    padding:0.5rem;
    overflow-y: scroll;
  }
}

.fileup{
  width: 50%;
  filter: invert(100%);
}


.file-upload-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-button:hover {
  background-color: #0056b3;
}

.comment-side {
  width: 33%;
  height: 50%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 1rem;
  box-shadow: 5px 5px 20px rgba(10, 19, 99, 0.15);
  padding-bottom: 3rem;
  background: var(--background-element);

  span{
    font-size: 12px;
    font-weight: bold;
  }

  .btn-gral {
    width: 60%;
    text-transform: uppercase;
    justify-content: center;
    margin-left: 4.5rem;
  }
}


