.footer-component{
    text-align: center;
    line-height: 3rem;
    background-color: var(--background-body);
    height: 3rem;
    font-size: .7rem;
    color: var(--color);
    padding-left: 18rem;
    border-top: var(--border);
}

@media(max-width:992px){
    .footer-component{
        padding-left: 0;
    }
}
