nav{
    ul{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        margin-right: 4rem;
    }
}
.paginator{
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        margin-right: 5px;
        color: white;
        padding: 9px;
        font-size: 1rem;
        border-radius: 5px;
  
        &:hover, &.current {
          background-color: var(--hover-paginator);
          color: white;
        }
      }
}