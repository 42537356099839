$grey: #05acdf;
$blue: #ea2fb2;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

@mixin loaderDivMixin {
  border-radius: 50%;
  padding: 2.5px;
  border: 2px solid transparent;
  animation: rotate linear 3.5s infinite;
}

.loader-mini {
  position: relative;
  margin: auto;
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
  div {
    height: 100%;
  }
}

.loader3,
.loader3 div {
  @include loaderDivMixin;
  border-top-color: $grey;
  border-left-color: $blue;
  animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
  animation-duration: 3s;
}

.loader,
.loader * {
  will-change: transform;
}
