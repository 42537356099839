.login-page {
  display: flex;
  height: 100vh;

  .login-side {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-box {
      width: 55%;

      &.error {
        span {
          position: relative;

          &::after {
            content: "*";
            font-weight: bold;
            color: var(--error);
            margin-left: 2px;
            line-height: 5px;
          }
        }

        input {
          border-color: var(--error);
        }
      }

      .brand {
        width: 100px;
        margin: 0 auto 2rem auto;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
        }
      }

      .btn-gral {
        width: 100%;
        margin-top: 1rem;
        font-weight: 300;
        font-family: var(--font-secondary);
      }
    }
  }

  .lateral {
    width: 60%;
    height: 100%;
    background: var(--gradient);
  }
}

@media (max-width: 992px) {
  .login-page {
    flex-direction: column-reverse;

    .login-side {
      width: 90%;
      margin: 0 auto;

      .login-box {
        width: 100%;
        max-width: 500px;
      }
    }

    .lateral {
      width: 100%;
      height: 4vh;
    }
  }
}
