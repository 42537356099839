.content-gral {
  .top-detail {
    h2 {
      display: flex;
      align-items: center;

      span {
        margin-left: 0.7rem;
      }
    }
    .box-form {
      width: 200px;
      margin-top: 20px;
    }
  }
  .content-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5rem;

    button {
      font-family: var(--font);

      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
.content-detail {
  margin-top: 1.6rem;
  display: flex;

  & > div {
    width: calc(100% / 3);
  }

  .box-detail:not(:last-child) {
    margin-right: 2%;
  }
}
.files-content-cols {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;

  .left-col-files {
    width: 66%;
    .table-container {
      margin: 0;
    }
  }

  .right-col-files {
    width: 32%;
  }
}
.box-client-details {
  margin: 1rem 0 1rem 0;
}
@media (max-width: 1100px) {
  .content-gral .content-btn {
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 2rem;

    button {
      width: 100%;
      margin: 0 0 0.8rem 0;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  .files-content-cols {
    flex-wrap: wrap;
    flex-direction: column;

    .left-col-files,
    .right-col-files {
      width: 100%;
      margin-bottom: 1.6rem;
    }

    .file-detail .btn-file-download {
      margin-left: auto;
    }
  }
}
