.nav-component {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--highlight-color);
  width: 16rem;
  height: 100vh;
  padding: 2rem 0 0 0;
  border-radius: 0 2rem 2rem 0;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);

  .logo {
    max-width: 80%;
    width: 70px;
    overflow: hidden;
    margin: 0 auto 14% auto;

    img {
      width: 100%;
    }
  }

  ul {
    padding: 0 0 0 2rem;
    list-style: none;

    li {
      font-size: 0.9rem;
      cursor: pointer;
      margin: 0 1rem 1rem 1rem;

      a {
        border-radius: 5px;
        padding: 0.8rem 1rem;
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        display: block;

        &:hover,
        &.active {
          background-color: rgba(255, 255, 255, 0.15);
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .nav-component {
    width: 80vw;
    transition: 0.4s ease-in-out all;
    transform: translateX(-80vw);
    box-shadow: none;

    &.active {
      box-shadow: 5px 0 20px rgba(0, 0, 0, 0.1);
      transform: none;
    }
  }
}
