.user-registration{
    .content-form{
        width: 70%;
        max-width: 550px;
        margin: 12vh auto 2rem auto;

        .line-form{
            margin-bottom: 2rem;
        }

        .btn-gral{
            width: 100%;
            background-color: var(--highlight-color);
        }
    }
}

@media(max-width: 992px){
    .user-registration{
        .content-form{
            width: 100%;
            padding: 0 1rem;
        }
    }
}