.table-container {
  .scrolleable {
    .empty-orders {
      color: white;
      text-align: center;
      margin-top: 2.5rem;
    }
    .filterContainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 2.5rem;
    }
    .inputFilter {
      input,
      select {
        width: 100%;
        padding: 0 1rem;
        background-color: transparent;
        color: white;
        border: var(--border);
        height: 40px;
        line-height: 40px;

        &.remove-border-right {
          border-right: 0;
        }
        option{
          color: black;
        }
      }
      input::placeholder {
        color: white;
      }
      h2 {
        color: white;
        margin: 0%;
        margin-bottom: 7px;
        font-size: 12px;
        font-weight: 500;
      }
    }

    table {
      tbody {
        tr {
          td.password {
            display: flex;
            font-family: var(--font-secondary);

            &:hover {
              img {
                opacity: 0.8;
              }
            }

            &.seeing-pass {
              button.view-btn {
                display: none;
              }

              button.hide-btn {
                display: block;
              }
            }

            button {
              border: none;
              background-color: transparent;
              outline: none;
              width: max-content;
              height: max-content;
              margin: 0 0 0 0.5rem;
              cursor: pointer;
              padding: 0;

              img {
                width: 1rem;
                height: 1rem;
                margin: 0;
                opacity: 0;
              }

              &.view-btn {
                display: block;
              }

              &.hide-btn {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.btn-info-order {
  border: none;
  background-color: transparent;
  outline: none;
  width: max-content;
  height: max-content;
  margin: 0 0 0 1rem;
  cursor: pointer;
  padding: 0;

  img {
    width: 1rem;
    height: 1rem;
    margin: 0;
    opacity: 100;
  }
}

@media (max-width: 1200px) {
  .table-container {
    .scrolleable {
      .filterContainer {
        justify-content: space-around;
        flex-direction: column;
        align-items: stretch;
        max-width: 100%;
      }
      input{
        margin-bottom: 1rem;
      }
      .inputFilter {
        h2 {
          font-size: 1.3rem;
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}
