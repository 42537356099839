.filters-container {
    padding-left: 25px;
      .inputFilter {
        margin-right: 3rem;
        input::placeholder {
          color: var(--input-color) !important;
        }
      }
    }

  .no-margin{
    margin-top: 0;
  }
  .table-container {
    .scrolleable {
      table {
        width: 100%;
        border-collapse: collapse;
      }
    }

    .center-table-items{
        table {
            tr{
               th{
                  text-align: start !important;
              }
            }
            td {
                text-align: start !important;

            }
        }
    }

    .td-with-input{
        display: flex ;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        input {
            padding: 0 1rem;
            width: 50%;
            background-color: transparent;
            color: white;
            border: var(--border);
            height: 40px;
            line-height: 40px;
            border-radius: .5rem;
            margin: 0rem 0rem 0rem 1.2rem;
        }
        .btn-gral{
          margin-left: -100px;
          height: 30px;
          width: 90px;
          padding: 0;
          font-size: .85rem;
          border: 0;
          -webkit-appearance: none;
        }
    }
  }

  .paginadorPa{
    display: flex;
    flex-direction: row;
  }
  